
    .newkf {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .newkf-header {
            /*display: flex;*/
            /*align-items: center;*/
            /*justify-content: space-between;*/
        }
    }

